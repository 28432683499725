import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Visacheck1 } from '../../../components/images/support/visa/Visacheck1.generated';
import { Visacheck2 } from '../../../components/images/support/visa/Visacheck2.generated';
import { Visacheck3 } from '../../../components/images/support/visa/Visacheck3.generated';
import { Visacheck4 } from '../../../components/images/support/visa/Visacheck4.generated';
import { Visacheck5 } from '../../../components/images/support/visa/Visacheck5.generated';
import { Visacheck6 } from '../../../components/images/support/visa/Visacheck6.generated';
import { Visacheck7 } from '../../../components/images/support/visa/Visacheck7.generated';
import { Visacheck8 } from '../../../components/images/support/visa/Visacheck8.generated';
import { Visacheck9 } from '../../../components/images/support/visa/Visacheck9.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "completing-a-visa-check"
    }}>{`Completing a Visa Check`}</h1>
    <p>{`In the previous section `}<a parentName="p" {...{
        "href": "/support/visa/adding-a-visa-details-task-to-a-workflow"
      }}>{`(visa details)`}</a>{`
we learnt how to collect visa details from an employee. This section shows how we can now
use those details to complete a visa check to determine the work rights for the employee.`}</p>
    <p>{`Lets get started 🚀`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click in to the employee we wish to complete a visa check on.`}</li>
    </ol>

    <Visacheck1 mdxType="Visacheck1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click into the workflow that contains the visa details task.`}</li>
    </ol>

    <Visacheck2 mdxType="Visacheck2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Scroll to the bottom of the task and click `}<strong parentName="li">{`Start visa check`}</strong></li>
    </ol>

    <Visacheck3 mdxType="Visacheck3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`If this is your first check you will need to set your notification preferences. Set
the number of days you would like notice for expiring visa's. Then click `}<strong parentName="li">{`Save settings and continue`}</strong>{`.`}</li>
    </ol>

    <Visacheck4 mdxType="Visacheck4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Select your payment preference and continue. Note - you will have to have a valid subscription
and be the subscription owner to proceed.`}</li>
    </ol>

    <Visacheck5 mdxType="Visacheck5" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`This form displays the data collected from the employee. These fields can be edited before
we run the check. When we are happy with the details and want to run a check click
`}<strong parentName="li">{`Run visa check`}</strong>{`.`}</li>
    </ol>

    <Visacheck6 mdxType="Visacheck6" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`The screen now displays the visa check history. Details of the employee work rights appear on this screen.`}</li>
    </ol>

    <Visacheck7 mdxType="Visacheck7" />
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Once we are done with our visa check we can mark the task as complete.`}</li>
    </ol>

    <Visacheck8 mdxType="Visacheck8" />
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`The workflow is now completed. `}</li>
    </ol>
    <p>{`Note that we can run a visa check at any time and review history
of past visa checks by clicking the `}<strong parentName="p">{`Visa check`}</strong>{` button`}</p>

    <Visacheck9 mdxType="Visacheck9" />
    <p>{`Learn how to review visa check history on the next page.`}</p>

    <SupportFooter linkTo="/support/visa/reviewing-visa-check-history" linkText="Learn how to review visa check history" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      